.BannerCity{
	color: white;
	display: flex;
	align-items: center;
	text-align: center;
	padding: 1.5rem 0.5rem 2rem 0.5rem;
}

.BannerCity__sub{
	    color: rgba(black, 0.6);
	    font-weight: 600;
    font-size: 1.5rem;
}
.BannerCity__name{
	color: white;
	text-transform: uppercase;
	font-family: $title_font;
    font-size: 2.2rem;
    line-height: 1.3;
    margin-bottom: 1rem;
}

@media (max-width: 1199px){
	.BannerCity {
		padding: 1rem 0.5rem 1.5rem 0.5rem;
	}
	.BannerCity__name {
		font-size: 1.8rem;
	}
}

.BannerCity__location {
	    color: rgba(black, 0.6);
    font-size: 1.2rem;
	    font-weight: 600;
    line-height: 1.3;
}

.BannerCity__partner {
	color: rgba(white, 0.6);
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 2;
}

.BannerCity__logo {
	height: 64px;
	text-align: center;
	margin-bottom: 1rem;
}

@media (min-width: 1200px){
	.BannerCity__logo {
		position: absolute;
		height: 150px;
		margin-right: 25px;
	}

	.BannerCity__logo a {
		line-height: 150px;
	}
}

.BannerCity__logo img {
	max-width: 200px;
	max-height: 100%;
	background: white;
}

.BannerCity .button{
	background-color: white;
	padding: 7px 1.6rem;
	min-width: unset;
	margin-top: 1rem;
	margin-bottom: 0;
	margin-right: 10px;

	&.green{color: $green;}
	&.red{color: $red;}
	&.yellow{color: $yellow;}
	&.blue{color: $blue;}
}