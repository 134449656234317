.BannerPresentation{
	padding: 4rem 15px;
	text-align: center;
	margin-bottom: 10px;

	background-size: cover;
	background-position: center center;
}

.BannerPresentation__content{
	max-width: 900px;
	margin: 0 auto;
}

.BannerPresentation .sectionTitle .sub{
	color: black;
	font-size: 1.3rem;
	letter-spacing: 2px;
}

.BannerPresentation__numbers{
	font-weight: 600;
	margin-bottom: 4rem;
}
.BannerPresentation__numbers .big{
	color: white;
	font-size: 3rem;
	font-weight: 700;
	line-height: 1.3;
}

.HighlightFrame {
	border: 2px solid #444444;
	background-color: #EEEEEE;
	max-width: 600px;
	padding: 12px;
	margin: 12px auto 18px;
	position: relative;
}

.HighlightFrame.bgyellow {
	border: 2px solid $yellow;
	background-color: rgba($yellow, 0.3) !important;
}

.HighlightFrame.bgblue {
	border: 2px solid $blue;
	background-color: rgba($blue, 0.3) !important;
}

.HighlightFrame.bggreen {
	border: 2px solid $green;
	background-color: rgba($green, 0.3) !important;
}