.EventNews {
  padding: 20px;
  border: 1px solid #444;
  box-shadow: 2px 5px 5px rgba(60,60,60, 0.5);
  font-size: 0.925rem;
  position: relative;
}

.EventNews.bigger-text {
  font-size: 1.125em;
}

.EventNews img {
  max-width: 100%;
}

.EventNews.future {
  border-color: rgba(255,0,0, 0.8);
  box-shadow: 2px 5px 5px rgba(255,0,0, 0.5);
}

.EventNews .published_date {
  color:rgba(60,60,60, 0.5);
}

.EventNews .published_date strong {
  color:rgba(60,60,60, 0.8);
}

.EventNews.future .published_date {
  color:rgba(255,0,0, 0.5);
}

.EventNews.future .published_date strong {
  color:rgba(255,0,0, 0.8);
}