.StickyContainer {
    position: sticky;
    top: -1px;
    padding-top: 134px;
}

@media(max-width: 1600px){
    .StickyContainer {
        padding-top: 109px;
    }
}

.StickyMenu {
    opacity: 0;
    transition: 200ms opacity ease-in-out;
}

.StickyMenu a.d-inline-block {
    display: block !important;
}

.StickyContainer.is-pinned .StickyMenu {
    opacity: 1;
}