$yellow:#f6a403;
$red:#e40f21;
$darkred: #4c0000;
$green:#37bb70;
// $blue:#19a0d8;
$blue: #01a1df;
$grey: #AAAAAA;

$title_font: "Cindie A";

$siteWideSM: 520px;
$siteWideMD: 658px;
$siteWideLG: 850px;
$siteWideXL: 1200px;


.black{ color: black; }
.black-20{ color: rgba(black, 0.2); }
.black-50{ color: rgba(black, 0.5); }
.black-80{ color: rgba(black, 0.8); }
.white{color: white;}
.blue{color: $blue !important;}

strong.white > a {
	color: white;
}