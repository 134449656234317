.carousel-control-next, .carousel-control-prev {
    width: 5%;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    padding: 10px;
    width: 32px;
    height: 32px;
    background-color: black;
    border-radius: 50%;
    background-origin: content-box;
}

#slideshow .carousel-item {
  height: 320px;
  background-position: center center;
  background-size: cover;
}

.container-fluid #slideshow .carousel-item {
  height: 400px;
}

#slideshow .carousel-item p {
  margin: 0;
}

#slideshow .carousel-item .textwhite a {
  text-decoration: underline;
  color: white;
}
#slideshow .carousel-item .textblack-80 a {
  text-decoration: underline;
	color: rgba(black, 0.8) !important;
}

@media (max-width: 991px) {
  #slideshow .carousel-item {
    height: 200px;
    font-size: 0.9rem;
    h5 {
        font-size: 1rem;
    }
  }
  .container-fluid #slideshow .carousel-item {
    height: 240px;
  }
}