.timeLine{
	position: relative;
	display: block;
    border-left:1px solid rgba(black,0.1);
    padding-left: 18px;
    padding-bottom: 15px;
    margin-left: 15px;
	    margin-bottom: -10px;
}


.timeLine:before{
	content: "";
	display: block;
	position: absolute;
	left: -5px;top:-6px;
	width: 10px;height: 10px;
	background-color: rgba(black,0.2);
	border-radius: 50%;
}

.bgblue .timeLine{
    border-left: 1px solid rgba(white,0.1);
}

.bgblue .timeLine:before{
	    background-color: rgba(white,0.2);
}

.bggrey .timeLine{
    border-left: 1px solid rgba($blue,0.3);
}

.bggrey .timeLine:before{
	    background-color: rgba($blue,0.85);
}

.timeLine .content{
	position: relative;
	    top: -13px;
	    margin-top: 0.8rem;
}
.timeLine__hour{
	    margin-right: 10px;
	    font-size: 1.1rem;
	    font-weight: 700;
}

.timeLine__title{
	font-weight: 400;
	font-size: 1.1rem;
}
.timeLine__title.highlight {
	font-weight: 600;
	font-size: 1.2rem;
}
.timeLine__subtitle{
	font-weight: 300;
    font-size: 0.85rem;
    opacity: 0.8;
	line-height: 1.1rem;
	min-height: 0.6rem;
}
.timeLine_separator {
	text-align: center;
	margin: -12px 0 24px;
}
.timeLine_separator .timeLine__title {
	text-transform: uppercase;
	font-size: 1.2rem;
	font-weight: 600;
}