.ButtonGroup {
    display: flex;
    margin: 20px 0;
    align-content: center;
    align-self: center;
}

.ButtonGroup a.button {
  border-radius: 0;
  margin: 0;
}