.Article{
	h2,h3,h4,h5,h6{
		color: $blue;
	}
}
.Article__header{
	height: 350px;
	background-size: cover;
	background-position: center;
	background-color: $blue;

	@include mobile{height: 250px;}
}

.Article__image{
	position: relative;
	top: -250px;
	margin-bottom: -200px;
	background-size: cover;
	background-position: center;
	height: 500px;
	width: 100%;

	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.Article__image .overlay{
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
	background: linear-gradient(0deg, rgba(0,0,0,0.3757878151260504) 40%, rgba(255,255,255,0) 50%);
}
.Article__image .content{
	position: relative;
	max-width: 500px;
}

.Article__title{
	color: #fff;
	font-weight: 700;
	font-size: 2rem;
	margin-bottom: 2rem;
}