.Pagination{
	position: relative;
	display: block;
	text-align: center;
	padding: 0.3rem;
}

.Pagination__number{
	font-family: $title_font;
	font-size: 1.5rem;
	color: rgba(black, 0.5);
	margin: 0 5px ;
	cursor: pointer;
}
.Pagination__prev .arrow{
	transform: rotate(180deg);
}
.Pagination__number:hover, .Pagination__number.active, .Pagination__prev:hover, .Pagination__next:hover{
	color: white;
}

.Pagination__next, .Pagination__prev{
    position: relative;
    top: -7px;
	color: rgba(white, 0.75);
	margin: 0 20px;
	cursor: pointer;
	.arrow{
		width: 15px;
	}
}
