.Concept {
    background-image: url('/assets/img/concept/bg.jpg');
    background-size: contain;
    background-color: $red;
    min-height: 450px;
    background-position: right bottom;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    padding: 2.5rem 15px;
    color: white;

    @include mobile{
    	background-image: unset;
    }
    @media(max-width: 991px){
        background-image: none;
    }
}

.Concept2 {
	background-image: url('/assets/images/accueil/heart-black.svg');
    background-position: 97% center;
    background-repeat: no-repeat;
    background-size: auto 70%;
    margin-bottom: 10px;
    padding: 4rem 15px;
    color: white;

    @include mobile{
    	background-image: unset;
    }
}

.Concept .sectionTitle,
.Concept2 .sectionTitle {
	    max-width: 600px;
}
.Concept .sectionTitle .sub{ color: $darkred; }
.Concept .text{ max-width:500px; }