.textgreen {
	color: $green !important;
}
.textyellow {
	color: $yellow !important;
}
.textred {
	color: $red !important;
}
.textblue {
	color: $blue !important;
}
.textwhite {
	color: white !important;
}
.textwhite-80 {
	color: rgba(white,0.8) !important;
}
.textwhite-50 {
	color: rgba(white,0.5) !important;
}
.textblack {
	color: black !important;
}
.textblack-80 {
	color: rgba(black, 0.8) !important;
}
.textblack-50 {
	color: rgba(black, 0.5) !important;
}

.font-weight-medium {
	font-weight: 500 !important;
}

a.textwhite,
a.textblack,
a.textblack-80,
a.textblack-50,
a.textblue,
a.textred,
a.textyellow,
a.textgreen {
	text-decoration: underline;
}
a.textgreen:hover {
	color: darken($green, 30%);
}
a.textyellow:hover {
	color: darken($yellow, 30%);
}
a.textred:hover {
	color: darken($red, 30%);
}
a.textblue:hover {
	color: darken($blue, 30%);
}
a.textwhite:hover {
	color: white;
}
a.textblack:hover {
	color: black;
}

.bggreen {
	background-color: $green !important;
}
.bgyellow {
	background-color: $yellow !important;
}
.bgred {
	background-color: $red !important;
}
.bgred-50 {
	background-color: #F49FA6 !important;
}
.bgyellow-50 {
	background-color: #FBDA9A !important;
}
.bgblue {
	background-color: $blue !important;
}
.bgblue-20 {
	background-color: #c6efff !important;
}
.bgblue-50 {
	background-color: #7FCFEE !important;
}
.bgwhite {
	background-color: white !important;
}
.bgwhite-80 {
	background-color: rgba(white, 0.8);
}
.bgwhite-50 {
	background-color: rgba(white, 0.5);
}
.bgwhite-20 {
	background-color: rgba(white, 0.2);
}
.bgblack {
	background-color: black;
}
.bgblack-50 {
	background-color: rgba(black, 0.5);
}
.bgblack-80 {
	background-color: rgba(black, 0.8);
}
.bgblack-20 {
	background-color: rgba(black, 0.2);
}
.bggrey {
	background-color: #EEEEEE;
}
.bgdarkgrey {
	background-color: #444444;
}
.bgimage {
	background-size: cover;
	background-position: center;
}

.outlinegreen {
	border: 5px solid $green;
}
.outlineyellow {
	border: 5px solid $yellow;
}
.outlinered {
	border: 5px solid $red;
}
.outlineblue {
	border: 5px solid $blue;
}
.outlinewhite {
	border: 5px solid white;
}
.outlineblack {
	border: 5px solid black;
}

.table1.bordered-table {
	display: none;
}

.bordered-table .row > div {
	border-style: solid;
	border-width: 0 16px 0 0;
	border-color: $green;
}

.bgwhite.bordered-table .row > div {
	border-color: white;
}

.bordered-table.border-y-0 .row > div {
	border-bottom-width: 0;
}

@media (max-width: 767px) {
	.table0.bordered-table .row > div:last-child {
		display: none;
	}

	.table1.bordered-table .row > div:first-child {
		display: none;
	}

	.table1.bordered-table {
		display: block;
	}

	.bordered-table .row > div {
		border-left-width: 16px;
	}
}

.bordered-table .row:first-child > div {
	border-top-width: 6px;
}

.bordered-table .row > div:first-child {
	border-left-width: 16px;
}

.white-50 {
  color: rgba(255, 255, 255, 0.6);
}

.blue-50 {
  color: rgba($blue, 0.6);
}

.red-50 {
  color: rgba($red, 0.6);
}

.yellow-50 {
  color: rgba($yellow, 0.6);
}

.green-50 {
  color: rgba($green, 0.6);
}

.black{
  color: #222222;
}
.black-20 {
   color: rgba(black, 0.3);
}
.black-50 {
   color: rgba(black, 0.6);
}
.white {
  color: white;
}
strong.white > a {
	color: white;
}

.green {
	color: $green !important;
}

.yellow {
	color: $yellow !important;
}

.red {
	color: $red !important;
}

.blue,
strong.textblue > a {
	color: $blue !important;
}