.foldable > .fold-content {
	transition: max-height 400ms;
	max-height: 450px;
}

.folded > .fold-content {
	max-height: 0;
	overflow: hidden;
}

.fold-header {
	cursor: pointer;
	padding-right: 20px;
}

.fold-header:before {
	content: " - ";
	font-size: 40px;
	position: absolute;
	line-height: 48px;
	right: 25px;
}

.folded .fold-header:before {
	content: "+";
}