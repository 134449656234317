.CardsCities .col-12{
	padding-right: 5px;
    padding-left: 5px;
}
.CardCity{
	width: 100%;
	margin-bottom: 10px;
	text-align: center;
	min-width: 260px;
	padding: 0 6px;
}

.CardCity__map{
    max-width: 35%;	
    margin-bottom: 2rem;
}
.CardCity__date{
	    color: rgba(black, 0.85);
	    text-transform: uppercase;
	    font-weight: 600;
    font-size: 1.2rem;
}
.CardCity__city{
	min-height: 6rem;
    margin-bottom: 1.4rem;	
}
.CardCity__title{
	color: white;
	text-transform: uppercase;
	font-family: $title_font;
    font-size: 1.8rem;
    line-height: 1.2;
    margin-bottom: 1.2rem;	
}
.CardCity__city .CardCity__title {
    margin-bottom: 1rem;
}
.CardCity__partner{
	color: rgba(black, 0.7);
	font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.2;
}
.CardCity__location {
	font-size: 1.2rem;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 1.8rem;
	color: rgba(black, 0.6);
	margin-bottom: 1rem;
	min-height: 46px;
}

.CardCity__caption{
	color: rgba(255, 255, 255, 0.4);
}


.CardCity__footer .button{
	padding: 7px 2rem;
	min-width: unset;
	margin-right: 10px;
	white-space: nowrap;

	&.green{color: $green;}
	&.red{color: $red;}
	&.yellow{color: $yellow;}
	&.blue{color: $blue;}
}
.CardCity__footer .more{
	color: white;
	white-space: nowrap;
}

.city-button {
	display: inline-block;
	color: white;
	border: 2px solid rgba(255,255,255,0.2);
	background: rgba(255,255,255,0.1);
	padding: 6px 8px;
	margin: 4px;
	border-radius: 6px;
	text-align: center;
	width: 280px;
	vertical-align: middle;
	margin: 8px 4px;
}

.city-button.bggreen {
	border: 2px solid $green;
	background: rgba($green, 0.95) !important;	
}

.city-button.bgred {
	border: 2px solid $red;
	background: rgba($red, 0.95) !important;	
}

.city-button.bgblue {
	border: 2px solid $blue;
	background: rgba($blue, 0.95) !important;	
}
.city-button.bgyellow {
	border: 2px solid $yellow;
	background: rgba($yellow, 0.95) !important;	
}

.city-header {
	width: 200px;
	padding: 8px 20px;
	display: inline-block;
	color: rgba(68, 68, 68, 0.6);
	font-size: 1.2rem;
	text-transform: uppercase;
	font-weight: bold;
	vertical-align: middle;
}

.city-button h3 {
/*	font-family: 'Cindie A', sans-serif; 
	text-transform: uppercase;
	font-size: 1.4rem;*/
}

.city-button:hover {
	border: 2px solid rgba(255,255,255,1);
	background: rgba(255,255,255,0.2);
	text-decoration: none;
	color: white;
}

.city-button.bggreen:hover {
	border: 2px solid $green;
	background: rgba($green, 0.7) !important;	
}

.city-button.bgred:hover {
	border: 2px solid $red;
	background: rgba($red, 0.7) !important;
}

.city-button.bgblue:hover {
	border: 2px solid $blue;
	background: rgba($blue, 0.7) !important;
}
.city-button.bgyellow:hover {
	border: 2px solid $yellow;
	background: rgba($yellow, 0.7) !important;
}