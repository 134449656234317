@font-face {
  font-family: 'Cindie A';
  src: url('/assets/fonts/CindieMono-A.ttf') format('truetype');      
  font-weight: normal;
  font-style: normal;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: 'Roboto', sans-serif;
}

input:focus, button:focus{outline: 0;}

.footnote {
  font-size: 0.9rem;
  opacity: 0.9;
  margin-top: 5px;
}