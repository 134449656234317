.lineIcon{
	    font-size:1.5rem;
	    margin-bottom: 1.4rem;
    	font-weight: 200;
    	width: 100%;
	  &.smaller {
	    font-size: 1.2rem;
	    margin-bottom: 1rem;
	  }
	  @media (min-width: 1200px){
    	width: 80%;
	    font-size: 1.65rem;
	    &.smaller {
		   font-size: 1.3rem;
	    }
	  }
}
.lineIcon .icon{
	margin-right: 20px;
	svg, img {
		height: 40px;
	}
}

.lineIcon.smaller .icon{
	  svg, img {
		    height: 32px;
	  }
}

.lineIcon div.icon {
  float: left;
  margin-top: 10px;
}

.lineIcon div.text {
	margin-left: 65px;
	margin-bottom: 0;
	padding-top: 0.4em;
}

.lineIcon div.multiline {
  line-height: 0.8em;
}

.lineIcon span.text {
	vertical-align: middle;
}

.lineIcon .caption {
  font-size: 0.9rem;
}
.lineIcon .bold{font-weight: 600;}

.lineIcon.red{
	svg, img {color: $darkred;}
}
.lineIcon.black{
	svg, img {color: rgba(black, 0.4);}
}

.BannerPresentation__numbers .icon {
	svg, img {
		height: 48px;
		color: rgba(black, 0.5);
	}
}